<template src="./dashboard.htm"></template>


<script>
import { mapState } from 'vuex';
// import moment from 'moment';
import moment from 'moment-timezone'
import Footer from '../../layouts/footer/Footer.vue';
import SubHeader from '../../layouts/sub_header/Subheader.vue';
import env from '../../../src/services/env';

export default {
  name: "BasicDashboard",
  components: {
      'footer-component': Footer,
      'sub-header': SubHeader,
  },
  data: () => ({
    json_data:[],
    count_today: null,
    count_month: null,
    count_to_date: null,
    header_title: env.title,
    header_address: env.address,
    view_table: true,
    overlay: true,
    view_info: false,
    view_info_location: false,
    status_dialog: false,
    dialog: '',
    type:  ['CSV'],
    exportFile: [],
    infos: [],
    persons: [],
    interval: null,
    time: null,
    date_now: moment().format('LL'),
    menu1: false,
    menu: false,
    items: [],
    fill: true,
    name:'',
    lastRecord: '',
    is_admin: '',
    updateForm: {
      status: ''
    },
    person: {
      id:'',
      name: '',
      status: '',
      contact: '',
      address: '',
      serial: '',
      secondary_name: '',
      secondary_relation: '',
      secondary_contact: ''
    },
     status_items: ['well', 'positive'],
    userprofile: [
      { title: "My Profile" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" }
    ],
    searchParams: {
        location: '',
        to: '',
        from: '',
        name: '',
    },
    person_header: [
          { 
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          // { text: 'Wearable Number', value: 'person_tag' },
         // { text: 'Status', value: 'status_proper' },
          // { text: 'Entry', value: 'onEntry_proper' },
          { text: 'Exit', value: 'onExit_proper' },
          { text: 'Date', value: 'date_proper' },
        ],
    info_header: [
          { 
            text: 'Location',
            align: 'start',
            sortable: false,
            value: 'location_name',
          },
          { text: 'Number of Contacts', value: 'number_contacts' },
          { text: 'Entry', value: 'onEntry_proper' },
          { text: 'Exit', value: 'onExit_proper' },
          { text: 'Date', value: 'date_proper' },
        ],
    location_header: [
          { 
            text: 'Location',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          // { text: 'Wearable Number', value: 'number' },
          //{ text: 'Status', value: 'status' },
          // { text: 'Entry', value: 'entry' },
          { text: 'Exit', value: 'exit' },
          { text: 'Date', value: 'date' },
        ],
    
  }),
  computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000)
  },
  methods: {
    handleClick(row) {  

      this.getPersonLogs(row.person_id);
      this.getPerson(row.person_id);
      this.getPersonSecondaryContact(row.person_id);

      this.view_table = false;
      this.view_info = true;
    },
    getPersonLogs(id) {
      this.$api
          .get('/person/'+id+'/logs')
          .then(res => {
            this.infos = res.data.map((logs) => {
                return {
                  ...logs,
                  onEntry_proper: logs.onEntry ? moment(logs.onEntry).utc().format('LT') : '',
                  onExit_proper: logs.onExit ? moment(logs.onExit).utc().format('LT') : '',
                  date_proper: logs.created_at ? moment(logs.created_at).utc().format('LL') : '',
                };
              });
            // this.items = res.data.data;
          })
          .catch(err => {
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    getPerson(id) {
      this.overlay = true;
      this.$api
          .get('/person/'+id)
          .then(res => {
            let data = res.data;
            this.overlay = false;
            if(data) {
              this.person.id = data.id;
              this.person.name = data.name;
              this.person.serial = data.serial;
              this.person.status = data.status;
              this.person.contact = data.mobile_number;
              this.person.address = data.street_address+' '+data.barangay+' '+data.municipality+', '+data.province;
              this.person.status_proper = data.status === 'positive' 
                    ? 'positive' 
                    : data.close_contact == null ? 'well' : 'close contact'
              this.person.status_class = data.status === 'positive' 
                    ? 'red-dot' 
                    : data.close_contact == null ? 'green-dot' : 'yellow-dot'
              
            }
          })
          .catch(err => {
              this.overlay=false;
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    getPersonSecondaryContact(id) {
       this.$api
          .get('/person/'+id+'/secondary-contact')
          .then(res => {
            let data = res.data;

           if(data) {
              this.person.secondary_name = data.full_name+' - '+data.relationship;
              this.person.secondary_contact = data.contact_number;
              this.person.secondary_relation = data.relationship;
            }
          })
          .catch(err => {
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    goToList() {
      this.view_table = true;
      this.view_info = false;
    },
    create() {
      this.tasks.push({
        done: false,
        text: this.task
      });

      this.task = null;
    },
   lastUpdateClick() {

      let vm = this;
      vm.$toast.open({
                  message:  'Last Update Button click!',
                  type: 'success',
                  position: 'bottom'
              })
    },
    search() {

      let vm = this;
      vm.overlay = true;
      this.persons = [];
      this.$api
          .get('/log/search?location_id='+ vm.searchParams.location+
                '&to='+ vm.searchParams.to+
                '&from='+ vm.searchParams.from+
                '&name='+vm.searchParams.name)
          .then(res => {
            
              this.persons = res.data.data.map((person) => {
                return {
                  ...person,
                  onEntry_proper: person.onEntry ? moment(person.onEntry).utc().format('LT') : '',
                  onExit_proper: person.onExit ? moment(person.onExit).utc().format('LT') : '',
                  date_proper: person.created_at ? moment(person.created_at).utc().format('LL') : '',
                  status_proper: person.status === 'positive' 
                    ? 'positive' 
                    : person.close_contact == null ? 'well' : 'close contact',
                  status_class: person.status === 'positive' 
                    ? 'red-dot' 
                    : person.close_contact == null ? 'green-dot' : 'yellow-dot',
                };
              });
              vm.overlay = false;
          })
          .catch(err => {
              vm.overlay = false;
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    getLastRecord() {

      this.$api
          .get('/log/last-update')
          .then(res => {
            let data = res.data.data;
            this.lastRecord = `Last updated on ${moment(data.created_at).format('llll')}` 
          })
          .catch(err => {
          this.overlay = false;
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
      console.log(this.lastRecord);
    },
    location() {
      this.overlay = true;
      this.$api
          .get('/location')
          .then(res => {
            // res.data.data.push({id: 0, location_name: "All Location"});
            this.items = res.data.data;
            this.overlay = false;
          })
          .catch(err => {
          this.overlay = false;
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    handleDate() {
      if(this.searchParams.from && this.searchParams.to) {
        this.search();
      }
    },
    handleLocation(value) {
      let vm = this;

      vm.searchParams.location = value.location_id;

      this.view_table = true;
      this.view_info = false;

      this.search();
    },
    today() {
      this.searchParams.from = moment().format("YYYY-MM-DD");
      this.searchParams.to = moment().format("YYYY-MM-DD");

      this.search();
    },
    updateStatus()
    {
      this.$api
          .put(`person/${this.person.id}/status`, this.updateForm)
          .then(res => {
            this.status_dialog = false;
            this.$toast.open({
                message:  'Successfully updated !',
                type: 'success',
                position: 'bottom'
            })
          })
          .catch(err => {
            this.status_dialog = false;
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    export_file()
    {
      this.$api
          .get('/log/get-logdata/')
          .then(res => {

            let log_data = [];
            res.data.data.forEach(item => {
              log_data.push({
                'ID': `${item.id}`,
                'DateTime': `${moment(item.onExit).format('YYYY-MM-DD HH:mm:ss A')}`,
                'Location': `${item.location_name}`,
              });
            });
            this.json_data = log_data;
          })
          .catch(err => {
          this.overlay = false;
              this.$toast.open({
                  message:  err,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    get_log_name() {
      return `logs_${moment().format('YYYY_MM_DD_hh_mm_ss_A')}.csv`;
    },
    count_ice() {
      // get ice count to date
      this.$api
          .get('/log/get/logdatacount')
          .then(res => {
            console.log('to_date response is: ', res.data.data);
            this.count_to_date = res.data.data[0].log_count*4;
          })
          .catch(err => {
          this.overlay = false;
              this.$toast.open({
                  message:  err,
                  type: 'error',
                  position: 'bottom'
              })
          })

      // get ice count for the month
      this.$api
          .get('/log/get/logdatacount/bydate?type=month')
          .then(res => {
            console.log('response is: ', res.data.data);
            this.count_month = res.data.data[0].log_count*4;
          })
          .catch(err => {
          this.overlay = false;
              this.$toast.open({
                  message:  err,
                  type: 'error',
                  position: 'bottom'
              })
          })

      // get ice count today
      this.$api
          .get('/log/get/logdatacount/bydate?type=today')
          .then(res => {
            console.log('today response is: ', res.data.data);
            this.count_today = res.data.data[0].log_count * 4;
          })
          .catch(err => {
          this.overlay = false;
              this.$toast.open({
                  message:  err,
                  type: 'error',
                  position: 'bottom'
              })
          })

    }
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }

    this.getLastRecord();
    this.location();
    this.export_file();
    this.count_ice();
    
    this.name = this.user.name;
    this.is_admin = (this.user.is_admin==1 ? true : false);
  },
};
</script>
